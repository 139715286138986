import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAddProductToFavoriteMutation, useFetchProductByIdQuery, useFetchUserInfoQuery, useRemoveProductFromFavoriteMutation } from '../../store/store'
import Skeleton from '../../components/Skeleton'
import OrderCheck from './OrderCheck'
function ProductShowPage (): React.ReactElement {
  const { productId: productIdString } = useParams()
  if (productIdString === undefined) return <p>Product Id is not existed</p>
  const productId = parseInt(productIdString)
  const [orderQuantity, setOrderQuantity] = useState(1)
  const { data: product, isLoading: isProductLoading, error: isProductError, refetch } = useFetchProductByIdQuery(productId)
  const [open, setOpen] = useState(false)
  const [order, setOrder] = useState({
    storeId: -1,
    productName: '',
    quantity: 0,
    storeName: '',
    productId: -1,
    price: 0,
    address: '',
    timestamp: '',
    takeTime: '',
    startedAt: '',
    endAt: ''
  })
  const extractTime = (isoString: string): string => {
    const date = new Date(isoString)
    let hours: number | string = date.getUTCHours()
    let minutes: number | string = date.getUTCMinutes()

    hours = hours < 10 ? '0' + hours.toString() : hours.toString()
    minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()

    return `${hours}:${minutes}`
  }
  const { error = {} } = useFetchUserInfoQuery()
  const [addToFavorite, addResult] = useAddProductToFavoriteMutation()
  const [removeFromFavorite, removeResult] = useRemoveProductFromFavoriteMutation()
  const isLoggedIn = error.status !== 401
  const toggleLike = (): void => {
    if (isLoggedIn) {
      if (addResult.isLoading || removeResult.isLoading) return

      const action = product.isLiked === true ? removeFromFavorite(productId) : addToFavorite(productId)

      action.then(async () => await refetch()).catch(error => { console.error(error) })
    } else {
      location.replace('/users/sign_in')
    }
  }

  // Subtract quantity
  const subtractQuantity = (): void => {
    if (orderQuantity > 1) {
      setOrderQuantity(orderQuantity - 1)
    }
  }
  const handleClose = (): void => {
    setOpen(false)
  }
  const addQuantity = (): void => {
    if (orderQuantity < product.quantity) {
      setOrderQuantity(orderQuantity + 1)
    }
  }
  const getCurrentDate = (format = ''): string => {
    const date = new Date()
    const year = date.getFullYear()
    const month = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)

    if (format === 'YMD') {
      return `${year} ${month} ${day}`
    } else {
      const hour = `0${date.getHours()}`.slice(-2)
      const minute = `0${date.getMinutes()}`.slice(-2)
      return `${year} ${month} ${day} ${hour}:${minute}`
    }
  }

  const submitOrder = (): void => {
    if (orderQuantity > 0) {
      setOrder({
        storeId: product.storeId,
        productName: product.name,
        quantity: orderQuantity,
        startedAt: extractTime(product?.pickupStartedAt),
        endAt: extractTime(product?.pickupEndedAt),
        storeName: product.store.name,
        productId: product.id,
        price: product.price,
        address: product.store.address,
        timestamp: getCurrentDate(),
        takeTime: getCurrentDate('YMD')
      })

      setOpen(true)
    } else {
      console.error('You must order at least one item')
    }
  }

  let content
  if (isProductLoading) {
    content = <Skeleton times={1} />
  } else if (isProductError !== null && isProductError !== undefined) {
    content = <p>Opps! something wrong!</p>
  } else {
    content =
        <>
            <div className="px-8 py-4 bg-[#f8f3f3] rounded-b-2xl w-full flex justify-between items-center">
                <p className="font-[#474646] font-bold text-l">{product?.store.name}</p>

                <button id="add-to-favorites">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 fill-current ${product.isLiked === true ? 'text-red-500' : 'text-gray-500'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => { toggleLike() }}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </button>
            </div>

        <div className="px-6 py-4 bg-white">
            <img
                src={product.imageUrl}
                alt={product.name}
                className="w-full rounded-2xl"
            />
            <div className="flex justify-between my-3">
                <div className="py-2">
                    <h2 className="text-xl font-bold">{product.name}</h2>
                    <p className="text-sm text-gray-400">
                        可取餐時間: {extractTime(product?.pickupStartedAt)} ~ {extractTime(product?.pickupEndedAt)}
                    </p>
                </div>
                <div className="flex flex-col justify-center">
                    <h3 className="text-2xl text-[#E1A846] font-bold">
                        ${product.price}
                    </h3>
                </div>
            </div>

            <hr className="my-4 border-gray-200 border-1" />

            <p className="text-sm text-gray-500">
                取餐地址 ： {product?.store.address}
            </p>
            <p className="text-sm text-gray-500">
                餐點描述 ： {product.description}
            </p>

            <hr className="my-4 border-gray-200 border-1" />

            <div className="flex items-center justify-center">
                <button
                    onClick={subtractQuantity}
                    className="flex items-center justify-center w-20 h-20 text-4xl text-gray-500 bg-gray-100 border rounded-full"
                >
                    -
                </button>
                <span className="mx-10 text-4xl font-bold ">{orderQuantity}</span>
                <button
                    onClick={addQuantity}
                    className="flex items-center justify-center w-20 h-20 text-4xl text-gray-500 bg-gray-100 border rounded-full"
                >
                    +
                </button>
            </div>

            <div className="flex items-center justify-center my-3">
                <p className="text-xs text-gray-400">剩餘數量 ： {product.quantity}</p>
            </div>
            <button onClick={submitOrder} className="w-full h-12 mt-5 py-3 bg-[#F4db73] text-white font-bold rounded-l-xl rounded-r-xl">
                確認
            </button>

            <p className="my-5">&nbsp;</p>

            <OrderCheck open={open} setOpen={handleClose} order={order} />

        </div>
        </>
  }

  return (
        <div>
            {/* <BackHeader /> */}
            {content}
        </div>
  )
}

export default ProductShowPage
