import React, { useEffect } from 'react'
import { store, useAppDispatch } from './store/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Outlet, useNavigate } from 'react-router-dom'
import NavBar from './components/NavBar'
import { mountToWindow } from './utilies/helper'
import SearchPage from './pages/SearchPage'
import HomePage from './pages/HomePage'
import ProductShowPage from './pages/ProductShowPage'
import FavoritedProductsPage from './pages/FavoritedProductsPage'
import { type envState, updateEnv } from './store/store'
import AccountPage from './pages/AccountPage'
import OrderShowPage from './pages/OrderShowPage'
import OrderListPage from './pages/OrderListPage'

const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/app')
  }, [])

  // Render your component JSX here
  return (
      <p>Waiting for redirect</p>
  );
};

const Layout = () => {
  return (
    <div>
      <Outlet/>
      <NavBar/>
    </div>
  )
}

type AppProps = {
  env: envState
}

declare global {
  interface Window { env: envState; }
}

const App = (props: AppProps) => {
  const {
    env
  } = props;
  const dispatch = useAppDispatch();

  window.env = {
    ...window.env,
    ...env
  }
  useEffect(() => {
    dispatch(updateEnv(env))
  }, [])
  return (
    <Router>
      <Routes>
        <Route path="/liff_entry/:base64" element={<Redirect/>}/>
        <Route path="/liff_entry" element={<Redirect/>}/>
        <Route path="/app" element={<Layout/>} >
          <Route index element={<HomePage/>} />
          <Route path="products" element={<HomePage/>} />
          <Route path="products/:productId" element={<ProductShowPage/>} />
          <Route path="products/search" element={<SearchPage/>} />
          <Route path="orders/:id" element={<OrderShowPage/>} />
          <Route path="orders" element={<OrderListPage/>} />
          <Route path="account" element={<AccountPage />} />
          <Route path="favoritedProducts" element={<FavoritedProductsPage/>} />
        </Route>
      </Routes>
    </Router>
  )
}

const AppWrapper = (props: AppProps) => {
  return (
    <Provider store={store}>
      <App {...props } />
    </Provider>
  )
}

mountToWindow(AppWrapper, 'App')
