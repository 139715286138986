import React from 'react'
import { mountToWindow } from '@/utilies/helper'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import EmployeeHomePage from '@/pages/EmployeeHomePage'
import { store } from './store/store'
import { Provider } from 'react-redux'

const EmployeeApp = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/staff">
            <Route index element={<EmployeeHomePage />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  )
}

mountToWindow(EmployeeApp, 'EmployeeApp')
