import React, { useState } from 'react'
import { ConfirmationDialog } from './ConfirmationDialog'

interface ToggleProps {
  defaultChecked?: boolean
}

const Toggle: React.FC<ToggleProps> = ({ defaultChecked = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (isChecked && !e.target.checked) {
      setIsOpen(true)
    } else {
      setIsChecked(e.target.checked)
    }
  }

  const handleCancel = (): void => {
    setIsOpen(false)
  }

  const handleConfirm = (): void => {
    setIsChecked(false)
    setIsOpen(false)
  }

  return (
    <>
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={isChecked} onChange={handleChange} className="sr-only peer" />
        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      </label>
      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content={'是否停止販售此商品？'}
      />
    </>
  )
}

export default Toggle
