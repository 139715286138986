import React from 'react'
import { Link } from 'react-router-dom'

interface Order {
  id: number
  quantity: number
  status: string | null
  createdAt: string
  product: any
}

interface OrderCardProps {
  order: Order
}

const OrderListItem: React.FC<OrderCardProps> = ({ order }) => {
  let statusText = '確認中'
  let statusBgColor = 'bg-[#f4db73]'
  if (order.status !== 'pending') {
    statusText = '預約成功'
    statusBgColor = 'bg-[#98e3c4]'
  }

  const createdAtDate: Date = new Date(order.createdAt)
  const pad = (num: number): string => num.toString().padStart(2, '0')
  const formattedDate = `${createdAtDate.getFullYear()}-${pad(createdAtDate.getMonth() + 1)}-${pad(createdAtDate.getDate())}`

  return (
    <Link to={`${order.id}`}>
        <div className="relative flex mb-4 py-3 shadow">
            <img src={order.product.imageUrl} style={{ width: '80px', height: 'auto' }} />
            <div className='ml-2 text-sm text-gray-500'>
              <p className='text-base text-gray-900'>{order.product.store.name}</p>
              <p>{order.product.name}</p>
              <p>{formattedDate}</p>
            </div>
            <span className= 'absolute top-0 right-0 mr-2 mt-2 px-2 py-1 text-[#E1A846] font-bold'>$ {order.quantity * order.product.price}</span>
            <span className={`absolute bottom-0 right-0 mr-2 mb-2 px-2 py-1 rounded text-[#474646] ${statusBgColor}`}>{statusText}</span>
        </div>
    </Link>
  )
}

export default OrderListItem
