import { useFetchOrdersQuery } from '@/store/store'
import OrderList from './OrderList'
import Skeleton from '@/components/Skeleton'
import React from 'react'

const OrderListPage = (): React.ReactElement => {
  const { data, isLoading, error } = useFetchOrdersQuery()

  if (isLoading) return <Skeleton times={3} />
  if (error !== undefined && error !== null) return <p>{JSON.stringify(error)}</p>
  if (data === undefined) return <p>No orders found</p>
  return <OrderList orders={data} />
}

export default OrderListPage
