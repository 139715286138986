import React from 'react'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  content: string
}

export const ConfirmationDialog: React.FC<Props> = ({ isOpen, onCancel, onConfirm, content }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onCancel}></div>
        </div>

        <div className="inline-block align-middle bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all w-80">
          <div className="bg-white px-4 pt-5 pb-4 mt-3">
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {content}
              </p>
            </div>
          </div>
          <div className="px-4 py-3 mb-3">
            <button type="button" className="w-full h-12 mt-5 py-3 bg-[#F4db73] text-[#474646] font-bold rounded-l-xl rounded-r-xl" onClick={onConfirm}>
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
