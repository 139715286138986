import { createApi } from '@reduxjs/toolkit/query/react'
import autoCaseConvertQuery from './autoCaseConvertQuery'
import { type ProductType } from '@/data/product'

interface CreateOrderParamsType {
  productId: number
  quantity: number
}
interface CreateOrderResponseType {
  id: number
}
interface FetchOrderResponseType {
  id: number
  product: ProductType
  status: typeof STATUS[number]
  quantity: number
  createdAt: string
}

const STATUS = ['pending', 'inProgress', 'completed', 'canceled'] as const

const orderApi = createApi({
  reducerPath: 'order',
  baseQuery: autoCaseConvertQuery(),
  endpoints: (builder) => ({
    createOrder: builder.mutation<CreateOrderResponseType, CreateOrderParamsType>({
      query: (params) => ({
        url: '/api/orders.json',
        method: 'POST',
        data: params
      })
    }),
    updateOrder: builder.mutation<void, { id: number, status: typeof STATUS[number] }>({
      query: ({ id, status }) => ({
        url: `/api/orders/${id}.json`,
        method: 'PATCH',
        data: { status }
      })
    }),
    fetchOrders: builder.query<FetchOrderResponseType[], void>({
      query: () => ({
        url: '/api/orders.json',
        method: 'GET'
      })
    }),
    fetchOrder: builder.query<FetchOrderResponseType, number>({
      query: (id) => ({
        url: `/api/orders/${id}.json`,
        method: 'GET'
      })
    })
  })
})

export const orderReducer = orderApi.reducer
export const { useCreateOrderMutation, useFetchOrderQuery, useFetchOrdersQuery, useUpdateOrderMutation } = orderApi
export { orderApi }
