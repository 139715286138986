import React from 'react'
import { Link } from 'react-router-dom'
import { type ProductType } from '../../data/product'

interface ProductListItemProps {
  product: ProductType
}
function ProductListItem (props: ProductListItemProps): React.ReactElement {
  const {
    product: {
      id,
      imageUrl,
      name,
      price,
      pickupStartedAt,
      pickupEndedAt,
      store
    }
  } = props

  const distance: number = Number(store.distanceTo) / 1000
  const extractTime = (isoString: string): string => {
    const date = new Date(isoString)
    let hours: number | string = date.getUTCHours()
    let minutes: number | string = date.getUTCMinutes()

    hours = hours < 10 ? '0' + hours.toString() : hours.toString()
    minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()

    return `${hours}:${minutes}`
  }
  return (
        <Link to={`/app/products/${id}`}>
            <ul className="grid gap-y-2 bg-[#F8F3F3]">
                <li className="px-6 py-4 bg-white">
                    <div className="relative w-full aspect-[2/1]">
                        <img className="object-cover object-center w-full h-full rounded-2xl" src={imageUrl} alt={name} />
                        <span className="absolute right-0 p-2 text-right text-white text-sm bg-[#474646] bottom-5 opacity-95">{distance.toFixed(2)} km</span>
                    </div>
                    <div className="flex justify-between">
                        <div className="py-2">
                            <h2 className="text-base font-bold">{name}</h2>
                            <p className="text-xs text-gray-500">可取餐時間：{extractTime(pickupStartedAt)}~{extractTime(pickupEndedAt)}</p>
                        </div>
                        <div className="flex flex-col justify-center">
                            <h3 className="text-xl font-bold text-[#E1A846]">
                                ${price}
                            </h3>
                        </div>
                    </div>
                </li>
            </ul>
        </Link>

  )
}

export default ProductListItem
