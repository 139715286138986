import React from 'react'

interface ButtonSetProps {
  buttons: Array<{ name: string, type: string, url: string }>
  onClick: (type: string) => void
}

const ProductTypeBlocks: React.FC<ButtonSetProps> = ({ buttons, onClick }) => {
  return (
    <div className="p-4 grid grid-cols-2 gap-4">
      {buttons.map((button, index) => (
        <button
          key={button.name}
          className="flex flex-col items-center justify-center bg-[#faf4e1] rounded-lg p-4"
          onClick={() => { onClick(button.type) }}
        >
          <img src={button.url} alt={button.type} className="object-cover w-1/2 rounded-full h-1/2" />
          <h2 className="mt-4 text-m">{button.name}</h2>
        </button>
      ))}
    </div>
  )
}

export default ProductTypeBlocks
