import React from 'react'
import OrderListItem from './OrderListItem'

interface Order {
  id: number
  quantity: number
  status: string | null
  createdAt: string
  product: any
}

interface GroupedOrders {
  pending: Order[]
  establish: Order[]
  finish: Order[]
}

const STATUS_DISPLAY_NAMES = {
  pending: '未確認的訂單',
  establish: '預約的訂單',
  finish: '完成的訂單'
}

const OrderList: React.FC<{ orders: Order[] }> = ({ orders }) => {
  // Group orders by status, treating null as 'Establish'
  const groupedOrders = orders.reduce<GroupedOrders>(
    (groups, order) => {
      const status = order.status ?? 'establish'
      if (status in groups) {
        groups[status as keyof GroupedOrders].push(order)
      } else {
        console.log(status)
      }
      return groups
    },
    { pending: [], establish: [], finish: [] }
  )

  return (
    <>
      {Object.entries(groupedOrders).map(([status, orders]) => (
        <div className="px-5 py-5" key={status}>
          <h2 className="mb-4 text-sm text-gray-500">{STATUS_DISPLAY_NAMES[status as keyof typeof STATUS_DISPLAY_NAMES]}</h2>
          <div className="flex flex-col flex-wrap mb-10 ">
            {orders.map((order: Order) => (
              <OrderListItem key={order.id} order={order} />
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default OrderList
