import React, { useState, useEffect } from 'react'

interface CountdownProps {
  targetDate: Date
}

interface TimeLeft {
  hours?: number
  minutes?: number
  seconds?: number
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +targetDate - +new Date()
    let timeLeft: TimeLeft = {}

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => { clearTimeout(timer) }
  })

  const formatTime = (time: number): string => {
    return time < 10 ? `0${time}` : time.toString()
  }

  return (
    <div className="ml-7 text-2xl text-[#333333]">
      {formatTime(timeLeft.hours ?? 0)}:
      {formatTime(timeLeft.minutes ?? 0)}:
      {formatTime(timeLeft.seconds ?? 0)}
    </div>
  )
}

export default Countdown
