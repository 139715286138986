import React, { useState } from 'react'
import { ConfirmationDialog } from './ConfirmationDialog'
import { type StaffProductType } from '@/data/staffProduct'
import { useAddProductMutation } from '@/store/store'

interface EditFormProps {
  product: StaffProductType
  onCancel: () => void
}

const EditForm: React.FC<EditFormProps> = ({ product, onCancel }) => {
  const [addStaffProduct, { isLoading }] = useAddProductMutation()
  const [name, setName] = useState(product.name)
  const [price, setPrice] = useState(product.price)
  const [amount, setAmount] = useState(product.quantity)
  const [originPrice, setOriginPrice] = useState(product.original_price)
  const [startedAt, setStartedAt] = useState(product.pickup_interval_from)
  const [endedAt, setEndedAt] = useState(product.pickup_interval_to)
  const [isOpen, setIsOpen] = useState(false)
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
    console.log(name)
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPrice(Number(e.target.value))
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAmount(Number(e.target.value))
  }

  const handleStartedAtChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStartedAt(Number(e.target.value))
  }

  const handleEndedAtChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEndedAt(Number(e.target.value))
  }

  const handleOriginPriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setOriginPrice(Number(e.target.value))
  }

  const handleDelete = (): void => {
    console.log('Item deleted')
    setIsOpen(false)
  }

  const handleCancel = (): void => {
    setIsOpen(false)
  }

  let title = ''
  if (product.id === -1) {
    title = '新增商品'
  } else {
    title = '修改商品'
  }

  const addNewProduct = async () => {
    const newProduct = {
      name,
      price,
      original_price: originPrice,
      quantity: amount,
      pickup_interval_from: startedAt,
      pickup_interval_to: endedAt
    }

    try {
      await addStaffProduct(newProduct).unwrap()
      location.replace('/staff')
    } catch (error) {
      console.error('Failed to add product: ', error)
    }
  }

  return (
    <div className='bg-white w-full text-center absolute left-0 top-0 z-50 h-full'>
      <p className='text-base text-gray-800 mt-5'>{title}</p>
      <hr className='h-2 bg-gray-200 my-4' />
      <div className='px-7'>
        <p className='text-left text-sm'>商品名稱</p>
        <input type='text' name='商品名稱' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={name} onChange={handleNameChange} />
        <p className='text-left text-sm'>商品售價</p>
        <input type='number' name='本日售價' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={price} onChange={handlePriceChange} />
        <p className='text-left text-sm'>商品原價</p>
        <input type='number' name='原價' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={originPrice} onChange={handleOriginPriceChange} />
        <p className='text-left text-sm'>本日提供份數</p>
        <input type='number' name='本日提供份數' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={amount} onChange={handleAmountChange} />
        <p className='text-left text-sm'>可取餐時間</p>
        <div className='flex justify between items-center'>
          <input type='text' name='開始取餐時間' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={startedAt} onChange={handleStartedAtChange} />
          <p className='mx-3'>~</p>
          <input type='text' name='結束取餐時間' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2 mt-2 mb-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required value={endedAt} onChange={handleEndedAtChange} />
       </div>
       <button onClick={addNewProduct} className="w-full h-12 mt-5 py-3 bg-[#F4db73] text-[#474646] font-bold rounded-l-xl rounded-r-xl">
          儲存
      </button>
      <button onClick={() => { setIsOpen(true) }} className="w-full h-12 mt-5 py-3 bg-red-300 text-[#474646] font-bold rounded-l-xl rounded-r-xl">
          刪除商品
      </button>
      <button onClick={onCancel} className="w-full h-12 mt-5 py-3 bg-gray-300 text-[#474646] font-bold rounded-l-xl rounded-r-xl">
          取消
      </button>
      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
        content={'是否確定要刪除此商品'}
      />
      </div>
    </div>
  )
}

export default EditForm
