import React, { useState } from 'react'
import OrderCancelDialog from './OrderCancelDialog'
import { useFetchOrderQuery } from '@/store/store'
import { useParams } from 'react-router-dom'
import Countdown from './CountDown'

const OrderDetail = (): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { id } = useParams()
  const { data: order, isLoading, error } = useFetchOrderQuery(Number(id))

  const openDialog = (): void => {
    setDialogOpen(true)
  }

  if (isLoading || order === undefined) return <p>Loading</p>
  if (error !== undefined && error !== null) return <p>{JSON.stringify(error)}</p>

  const createdAtDate: Date = new Date(order.createdAt)
  const pickupDate: Date = new Date(order.product.pickupStartedAt)

  if (createdAtDate > pickupDate) {
    pickupDate.setDate(pickupDate.getDate() + 1)
  }
  console.log(pickupDate)
  function parseDate (input: string): string {
    const date = new Date(input)
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }
  function formatTimeRange (startTime: string, endTime: string): string {
    const startDate = startTime.split(' ')[0]
    const startTimePart = startTime.split(' ')[1]
    const endTimePart = endTime.split(' ')[1]

    return `${startDate} ${startTimePart}~${endTimePart}`
  }

  const content = (): JSX.Element => {
    if (order.status === 'pending') {
      return (
        <>
          <div className="flex justify-center my-10">
          <svg aria-hidden="true" style={{ width: '80px', height: '80px' }} className="text-[#98E3C4] dark:text-green-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
          </div>
          <p className="text-sm">已向店家預約商品</p>
        </>
      )
    } else {
      return (
        <>
          <p className="my-3 text-sm">訂單編號 : {order.id}</p>
          <div className="flex justify-center my-7">
            <img className="w-20" src="https://media.istockphoto.com/id/828088276/vector/qr-code-illustration.jpg?s=1024x1024&w=is&k=20&c=J3NoWzg4Y5x8x8tPjB-oscJ48ITem2axmKcX5lMusSU=" />
          </div>
        </>
      )
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white">
      <div className="w-full h-full py-5 overflow-auto text-center bg-white">
        <h2 className="text-l">訂單資訊</h2>
        <hr className="my-4 border-4 border-gray-200" />

          {content()}

        <div className="border-l-4 border-[#E1A846] mx-8 px-3 my-7">
          <p className="text-sm text-left">下單時間： {parseDate(order.createdAt)}</p>
          <p className="text-sm text-left">
            取餐時間：{formatTimeRange(parseDate(order.product.pickupStartedAt), parseDate(order.product.pickupEndedAt))}
          </p>
        </div>

        <hr className="my-4 border-gray-200 border-1" />

        <div className="mx-5 text-left">
          <p className="text-sm">訂單明細</p>
          <div className="flex justify-between my-1">
            <div className="py-2">
              <p className="text-sm text-gray-400">
                {order.product.name} x {order.quantity} 份
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[#E1A846] font-bold text-sm">${order.product.price}</p>
            </div>
          </div>
          <div className="py-2 text-center bg-gray-100 text-m">
            總計{order.quantity}項 / 共 {order.product.price * order.quantity} 元
          </div>
        </div>

        <hr className="my-4 border-gray-200 border-1" />

        <div className="mx-5 text-left">
          <p className="py-3 text-sm">店家資訊</p>
          <div>
            <p className="text-sm text-gray-400">
              店家名稱 ： {order.product.store.name}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-400">取餐地址 ： {order.product.store.address}</p>
          </div>

          <div className="fixed left-0 flex items-center justify-between w-full py-3 bg-gray-100 bottom-16 rounded-t-3xl">
            <div className="absolute left-0 flex items-center justify-center w-6 h-6 font-bold text-white bg-gray-300 rounded-full -top-2" onClick={openDialog}>
              x
            </div>
            <Countdown targetDate={pickupDate} />

              <button className="py-3 text-gray-400 bg-[#f4db73] rounded-l-xl rounded-r-xl mr-7 px-4 text-[#474646] text-xl">
                完成訂單
              </button>
          </div>
          {/* <button
            onClick={openDialog}
            className="w-full h-12 py-3 text-gray-400 bg-gray-100 mt-7 rounded-l-xl rounded-r-xl"
          >
            取消訂單
          </button> */}

          <div className="py-12">&nbsp;</div>
        </div>

        <OrderCancelDialog open={dialogOpen} setOpen={setDialogOpen} />
      </div>
    </div>
  )
}

export default OrderDetail
