import React from 'react'
import Toggle from './Toggle'
import ProductList from './ProductList'
import NewProduct from './NewProduct'
import {useFetchStaffStoreQuery } from '@/store/store'

const MerchantHomePage: React.FC = () => {
  const { data, isLoading, error } = useFetchStaffStoreQuery()

  if (isLoading) {
    return <p>Loading...</p>
  }
  if (data != null) {
    console.log(data)
    return <>
  <div className='px-7'>
    <div className='flex w-full py-5 justify-between'>
      <p className='font-bold'>{data.name}</p>
      <button>設定</button>
    </div>
    <p className='text-xs text-gray-500'>開放取餐時間：11:00~20:30</p>
    <div className='flex text-xs text-gray-500 justify-between py-2 items-center'>
        <p>全店立即關閉販售</p>
        <Toggle defaultChecked={true} />
    </div>
  </div>
  <hr className="h-2 bg-gray-200 my-4" />
  <ProductList />
  <NewProduct />
  <div className="my-5">&nbsp;</div>
  </>
  } else {
    console.log(error.message)
    return <p>Error occurred while fetching data.</p>
  }
}

export default MerchantHomePage
