import React, { useState } from 'react'
import Toggle from './Toggle'
import EditForm from './EditForm'
import { type StaffProductType } from '@/data/staffProduct'

const ProductListItem: React.FC<StaffProductType> = ({
  id,
  name,
  price,
  original_price,
  quantity,
  description,
  category,
  pickup_interval_from,
  pickup_interval_to,
  enabled
}) => {
  const [showEditForm, setShowEditForm] = useState(false)

  const handleEditClick = (): void => {
    setShowEditForm(!showEditForm)
  }

  const product = { id, name, price, original_price, quantity, description, category, pickup_interval_from, pickup_interval_to, enabled }

  return (
    <>
      <div className='flex justify-between px-7' onClick={handleEditClick}>
        <button className='text-gray-800' name={name}>{name}</button>
        <button>編輯</button>
      </div>
      {showEditForm && <EditForm product={product} onCancel={handleEditClick} />}
      <p className='px-7 text-xl font-bold text-[#E1A846]'>${price}</p>
      <div className='flex justify-between mb-2 px-7'>
        <p className='text-xs text-gray-500'>總份數 {quantity}</p>
        <Toggle defaultChecked={enabled} />
      </div>
    </>
  )
}

export default ProductListItem
