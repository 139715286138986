// SearchBar.js
import React, { useState } from 'react'

interface SearchBarProps {
  onSubmit: (term: string) => void
}

function SearchBar ({ onSubmit }: SearchBarProps): React.ReactElement {
  const [term, setTerm] = useState('')

  const handleFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    onSubmit(term)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTerm(event.target.value)
  }

  return (
    <div className="items-center justify-center w-full">
      <form onSubmit={handleFormSubmit}>
      <div className="relative">
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-2xl bg-[#f8f3f3] text-[#6d6f6f]"
          placeholder="搜尋餐廳、店家、商品名稱"
          onChange={handleChange}
          name = "q"
        />
        <svg aria-hidden="true" className="absolute w-5 h-5 text-gray-500 right-3 top-1/2 transform -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
            </path>
            </svg>
        </div>
      </form>
    </div>
  )
}

export default SearchBar
