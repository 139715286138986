import React from 'react'
import Header from '../../components/Header'
import SearchBar from '../../components/SearchBar'
import ProductTypeBlocks from './ProductTypeBlocks'
import { useNavigate } from 'react-router-dom'

const productsTypes = [
  { name: '來點好吃便當', type: '便當', url: 'https://media.istockphoto.com/id/1125518396/vector/chocolate-cupcake-icon.jpg?s=1024x1024&w=is&k=20&c=ayMUhamMH68QaPMmsG1S6nqKG4kVZh9Hkwa_4q43StU=' },
  { name: '水果', type: '水果', url: 'https://media.istockphoto.com/id/1125518396/vector/chocolate-cupcake-icon.jpg?s=1024x1024&w=is&k=20&c=ayMUhamMH68QaPMmsG1S6nqKG4kVZh9Hkwa_4q43StU=' },
  { name: '甜點', type: '甜食', url: 'https://media.istockphoto.com/id/1125518396/vector/chocolate-cupcake-icon.jpg?s=1024x1024&w=is&k=20&c=ayMUhamMH68QaPMmsG1S6nqKG4kVZh9Hkwa_4q43StU=' },
  { name: '喜愛的商品', type: '喜愛的商品', url: 'https://media.istockphoto.com/id/1125518396/vector/chocolate-cupcake-icon.jpg?s=1024x1024&w=is&k=20&c=ayMUhamMH68QaPMmsG1S6nqKG4kVZh9Hkwa_4q43StU=' }

]

const SearchPage = (): React.ReactElement => {
  const navigate = useNavigate()
  const handleSubmitSearch = (term: string): void => {
    navigate(`/app/products?q=${term}`)
  }

  const handleButtonClick = (type: string): void => {
    if (type === '喜愛的商品') {
      navigate('/app/favoritedProducts')
    } else {
      navigate(`/app/products?category=${type}`)
    }
  }

  return (
    <div>
        <Header />

      <div className="bg-white w-full mb-3 px-4 lg:px-6 py-2.5">
          <SearchBar onSubmit={handleSubmitSearch} />
      </div>
      <ProductTypeBlocks buttons={productsTypes} onClick={handleButtonClick} />
    </div>
  )
}

export default SearchPage
