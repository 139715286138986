import { useFetchUserInfoQuery } from '@/store/store'
import { Link } from 'react-router-dom'
import React from 'react'
import Skeleton from './skeleton'
const defaultUser = {
  name: 'User',
  imageUrl: 'https://media.istockphoto.com/id/1125518396/vector/chocolate-cupcake-icon.jpg?s=1024x1024&w=is&k=20&c=ayMUhamMH68QaPMmsG1S6nqKG4kVZh9Hkwa_4q43StU='
}

function AccountPage (): React.ReactElement {
  const { data: user = defaultUser, error = {}, isLoading } = useFetchUserInfoQuery()
  const isLoggedIn = error.status !== 401

  const handleRedirected = (): void => {
    location.replace('/users/sign_in')
  }

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      <div className="py-8">&nbsp;</div>
      <div className="flex items-center justify-between px-8">
        <h1 className="text-2xl">{user.name}</h1>
        <img className="w-16 h-16 rounded-full" src={user.imageUrl} alt={user.name}/>
      </div>
      <hr className="my-4 border-4 border-gray-200" />
      <ul className="p-6 text-gray-600 list-none">
        {isLoggedIn
          ? <li className="py-3 text-sm"><Link to="/app/favoritedProducts">喜愛的商品</Link></li>
          : <li onClick={handleRedirected} className="py-3 text-sm cursor-pointer"><a href="/users/sign_in">登入</a></li>
        }
        <li className="py-3 text-sm">優惠券</li>
        <li className="py-3 text-sm">聯絡客服</li>
        <li className="py-3 text-sm">隱私設定</li>
      </ul>
    </>
  )
}

export default AccountPage
