import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface envState {
  RAILS_ENV: 'development' | 'production' | 'test'
  GMAPS_API_KEY: string
}

const initialState = {
  RAILS_ENV: 'development',
  GMAPS_API_KEY: ''
}

export const envSlice = createSlice({
  name: 'env',
  initialState,
  reducers: {
    updateEnv: (state, action: PayloadAction<envState>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { updateEnv } = envSlice.actions
export default envSlice.reducer
