import React, { useState } from 'react'
import { useFetchProductsQuery } from '@/store/store'
import Skeleton from '@/components/Skeleton'
import ProductList from '@/components/ProductList'
import Header from '@/components/Header'
import SearchBar from '@/components/SearchBar'
import { useNavigate, useLocation } from 'react-router-dom'

function useQuery (): URLSearchParams {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function HomePage (): React.ReactElement {
  const navigate = useNavigate()
  const query = useQuery()
  let q = query.get('q')
  let category = query.get('category')
  const [userLocation] = useState<{ lat: number, lng: number }>({
    lat: 25.06951,
    lng: 121.58880
  })
  if (q !== undefined) {
    q = ''
  }

  if (category !== undefined) {
    category = ''
  }

  const { data, isLoading, error } = useFetchProductsQuery({ lng: userLocation.lng, lat: userLocation.lat, distanceTo: 50000, q, category })

  const handleSubmitSearch = (term: string): void => {
    navigate(`/app/products?q=${term}`)
  }

  const content = (): React.ReactElement => {
    let content: React.ReactElement
    if (isLoading) {
      content = <Skeleton times={3} />
    } else if (error !== null && error !== undefined) {
      console.log(error)
      if (typeof error === 'string') {
        content = <p> Fetching Data Error: {error}</p>
      } else if (error instanceof Error) {
        content = <p> Fetching Data Error: {error.message}</p>
      } else {
        content = <p> Fetching Data Error</p>
      }
    } else {
      content = <ProductList products={data} />
    }
    return content
  }

  return (
      <>
        <Header />

        <div className="bg-white w-full mb-3 px-4 lg:px-6 py-2.5">
          <SearchBar onSubmit={handleSubmitSearch} />
        </div>
        <div className="min-h-screen">
          {content()}
        </div>
      </>
  )
}

export default HomePage
