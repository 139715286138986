import React, { useEffect, useState } from 'react'
import { useCreateOrderMutation } from '@/store/store'
import { useNavigate } from 'react-router-dom'

interface OrderType {
  storeId: number
  productName: string
  quantity: number
  startedAt: string
  endAt: string
  storeName: string
  productId: number
  price: number
  address: string
  timestamp: string
  takeTime: string
}

interface OrderCheckProps {
  open: boolean
  setOpen: (open: boolean) => void
  order: OrderType
}

const OrderCheck: React.FC<OrderCheckProps> = ({ open, setOpen, order }) => {
  const navigate = useNavigate()
  const [createOrder, result] = useCreateOrderMutation()
  const [alertMsg, setAlertMsg] = useState('')
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])
  const close = (): void => {
    setOpen(false)
  }

  if (result.isError) {
    if (result.error?.status === 401) {
      location.replace('/users/sign_in')
    } else if (result.error?.status === 402) { // Add this line
      setAlertMsg('Product is sold out') // And this line
    }
  }

  useEffect(() => {
    if (result.isSuccess) {
      setOpen(false)
      navigate(`/app/orders/${result.data?.id}`, { replace: true })
    } else if (result.isError) {
      setAlertMsg('Product is sold out')
    }
  }, [result.isSuccess, result.isError, navigate, setOpen, result.data?.id])

  const submitOrder = (): void => {
    if (result.isLoading) return
    const params = {
      productId: order.productId,
      quantity: order.quantity
    }
    console.log(result)
    void createOrder(params)
  }

  return (
    <>
      <div onClick={close} className={`fixed inset-0 z-20 transition-opacity ease-linear ${open ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}></div>
      <div className={`fixed inset-x-0 bottom-0 z-30  transition-transform transform ease-out duration-300 ${open ? 'translate-y-0' : 'translate-y-full'}`}>
        <div className="w-full h-full overflow-auto text-center bg-white">
          <div className="p-4 text-center">
            <h2 className="font-bold text-l">{order.storeName}</h2>
            <hr className="my-4 border-gray-200 border-1" />
            <p className="text-sm text-gray-400">取餐時間：{order.startedAt}~{order.endAt}</p>
            <p className="text-sm text-gray-400">請於取餐時間內取餐</p>
            <hr className="my-4 border-gray-200 border-1" />
          </div>

          <div className="mx-5">

            <div className="mx-5 text-left">
              <p className="text-sm">訂單明細</p>
              <div className="flex justify-between my-1">
                <div className="py-2">
                  <p className="text-sm text-gray-400">
                    {order.productName} x {order.quantity} 份
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-sm font-bold text-">${order.price}</p>
                </div>
              </div>
              <div className="py-2 text-center bg-gray-100 text-m">
                總計{order.quantity}項 / 共 {order.price * order.quantity} 元
              </div>

              <div className='py-2 text-center text-red-400'>{alertMsg}</div>

              <button onClick={submitOrder} className="w-full h-12 mt-5 py-3 bg-[#F4db73] text-white font-bold rounded-l-xl rounded-r-xl">
              下單
            </button>
            </div>

          </div>
        <div className="my-3">&nbsp;</div>
        </div>
      </div>
    </>
  )
}

export default OrderCheck
