import React from 'react'

function Skeleton (): React.ReactElement {
  return (
            <div role='status' className='w-full animate-pulse md:p-6 dark:border-gray-700 mt-10'>
                <div className='flex items-center justify-between px-8'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2'></div>
                    <svg className='text-gray-200 w-14 h-14' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z' clipRule='evenodd'></path></svg>
                </div>
                <hr className='my-4 border-4 border-gray-200' />
                <div className='px-8'>
                    <div className='h-3 bg-gray-200 rounded-full w-32 my-7 mt-10'></div>
                    <div className='h-3 bg-gray-200 rounded-full w-32 my-7'></div>
                    <div className='h-3 bg-gray-200 rounded-full w-32 my-7'></div>
                    <div className='h-3 bg-gray-200 rounded-full w-32 my-7'></div>
                </div>
            </div>
  )
}

export default Skeleton
