import React from 'react'

function Header (): React.ReactElement {
  return (
    <header>
      <nav className="px-4 py-2.5 bg-[#f8f3f3] rounded-b-2xl">
        <div className="flex items-center justify-between mx-auto max-w-screen-xl">
          <div className="flex flex-col items-start">
            <p className="mx-1 mb-1 text-xs text-gray-500">正在使用:</p>
            <div className="flex items-center">
              <p className="mx-1 text-xs">目前位置</p>
              <div className="relative flex w-2 h-2">
                <div className="absolute w-full h-full bg-green-400 rounded-full opacity-75 animate-ping"></div>
                <div className="relative w-2 h-2 bg-green-500 rounded-full"></div>
              </div>
              {/* <span className="mx-2 text-xs text-gray-400">&#9660;</span> */}
            </div>
          </div>
          <div className="mr-2">
            <svg className="w-6 h-6 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.75 4H19M7.75 4a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 4h2.25m13.5 6H19m-2.25 0a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 10h11.25m-4.5 6H19M7.75 16a2.25 2.25 0 0 1-4.5 0m4.5 0a2.25 2.25 0 0 0-4.5 0M1 16h2.25"/>
            </svg>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
