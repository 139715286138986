import React, { useState } from 'react'
import EditForm from './EditForm'

interface ProductProps {
  id: number
  name: string
  price: number
  amount: number
  ordersAmount: number
  status: boolean
  startedAt: string
  endedAt: string
}

const NewProduct: React.FC = () => {
  const [showEditForm, setShowEditForm] = useState(false)

  const handleEditClick = (): void => {
    setShowEditForm(!showEditForm)
  }

  const emptyProduct: ProductProps = {
    id: -1,
    name: '',
    price: 0,
    amount: 0,
    ordersAmount: 0,
    status: false,
    startedAt: '',
    endedAt: ''
  }

  return (
      <>
        <button className='flex justify-between mx-6 bg-gray-100 py-7 px-5' onClick={handleEditClick}>
          <p>新增商品</p>
          <p>+</p>
        </button>
        {showEditForm && (
          <EditForm product={emptyProduct} onCancel={handleEditClick} />
        )}
      </>
  )
}

export default NewProduct
