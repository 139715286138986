import React from 'react'
import ProductListItem from './ProductListItem'
import { type StaffProductType } from '@/data/staffProduct'
import { useFetchAllProductsQuery } from '@/store/store'

const ProductList: React.FC = () => {
  const { data, isLoading, error } = useFetchAllProductsQuery()
  if (isLoading) {
    return <p>loading...</p>
  }
  if (data != null) {
    return (
      <>
        <ul className="grid gap-y-2">
          {data.map((product: StaffProductType) => (
            <>
              <ProductListItem key={product.id} {...product} />
              <hr className='my-3' />
            </>
          ))}
        </ul>
      </>
    )
  } else {
    return <p>{error.message}</p>
  }
}

export default ProductList
