import React from 'react'
import ProductListItem from './ProductListItem'
import { type ProductType } from '../../data/product'

interface ProductListProps {
  products: ProductType[]
}

function ProductList (props: ProductListProps): React.ReactElement {
  const { products } = props

  return (
    <>

      <ul className="grid gap-y-2 bg-[#F8F3F3]">
        {products.map((product) => (
          <ProductListItem key={product.id} product={product} />
        ))}
      </ul>
      <div className="my-5">&nbsp;</div>
    </>
  )
}

export default ProductList
