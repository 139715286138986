import React, { useEffect } from 'react'
import { useUpdateOrderMutation } from '@/store/store'

interface OrderCheckProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const OrderCancelDialog: React.FC<OrderCheckProps> = ({ open, setOpen }) => {
  const [updateOrder, result] = useUpdateOrderMutation()
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  const close = (): void => {
    setOpen(false)
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation()
  }

  const cancelOrder = (): void => {
    if (result?.isLoading) return
    void updateOrder({ id: 1, status: 'canceled' })
  }

  if (result.isSuccess) { setOpen(false) }

  return (
    <>
      <div
        onClick={close}
        className={`fixed inset-0 z-20 transition-opacity ease-linear ${open ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
      />
      <div
        className={`
          fixed inset-0 flex items-center justify-center z-30
          transition-transform transform ease-out duration-300
          ${open ? 'translate-y-0' : 'translate-y-full'}
        `}
      >
        <div
          onClick={stopPropagation}
          className={`
            bg-white rounded-lg p-7
            transition-transform transform
            w-3/4 md:w-1/2 lg:w-1/3 text-[#6d6d6d]
            ${open ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}
          `}
        >
          若臨時取消拿取餐點或未取達兩次，將會停權48小時無法使用本平台。
        <div className="flex justify-center mt-4 space-x-2">
           <button onClick={close} className="w-24 h-12 px-4 py-2 bg-[#f4db73] text-[#474646] rounded-full">
            返回
          </button>
          <button onClick={cancelOrder} className="w-24 h-12 px-4 py-2 bg-gray-300 text-[#474646] rounded-full">
            確定取消
          </button>
        </div>

        </div>
      </div>
    </>
  )
}

export default OrderCancelDialog
