import { createApi } from '@reduxjs/toolkit/query/react'
import { type Store } from '../../data/store'
import { type StaffProductType } from '@/data/staffProduct'
import autoCaseConvertQuery from './autoCaseConvertQuery'

interface LocationQueryType {
  lng: number
  lat: number
  distanceTo: number
}
interface StaffStore {
  id: number
  name: string
  address: string
}
interface ProductTypeParams {
  name: string
  price: number
  original_price: number
  quantity: number
  pickup_interval_from: number
  pickup_interval_to: number
}
const storeApi = createApi({
  reducerPath: 'store',
  baseQuery: autoCaseConvertQuery(),
  endpoints: (builder) => ({
    fetchStoreById: builder.query<Store, number>({
      query: (id) => ({
        url: `/api/stores/${id}.json`,
        method: 'GET'
      })
    }),
    fetchAllStores: builder.query<Store[], void>({
      query: () => ({
        url: '/api/stores.json',
        method: 'GET'
      })
    }),
    fetchStoreByTerm: builder.query<Store[], LocationQueryType>({
      query: (term) => ({
        url: '/api/stores.json',
        method: 'GET',
        params: term
      })
    }),
    fetchAllProducts: builder.query<StaffProductType[], void>({
      query: () => ({
        url: '/api/store/products',
        method: 'GET'
      })
    }),
    fetchStaffStore: builder.query<StaffStore, void>({
      query: () => ({
        url: '/api/store',
        method: 'GET'
      })
    }),
    addProduct: builder.mutation<void, ProductTypeParams>({
      query: (params) => ({
        url: '/api/store/products',
        method: 'POST',
        data: params
      })
    })
  })
})

export const storeReducer = storeApi.reducer
export const { useFetchStoreByIdQuery, useFetchAllStoresQuery, useFetchStoreByTermQuery, useFetchAllProductsQuery, useFetchStaffStoreQuery, useAddProductMutation } = storeApi
export { storeApi }
