import React from 'react'
import { useFetchProductByFavoriteQuery } from '../../store/store'
import Skeleton from '../../components/Skeleton'
import ProductList from '@/components/ProductList'
import Header from '../../components/Header'
import SearchBar from '../../components/SearchBar'
import { useNavigate } from 'react-router-dom'

function HomePage (): React.ReactElement {
  const navigate = useNavigate()
  const handleSubmitSearch = (term: string): void => {
    navigate(`/app/products?q=${term}`)
  }
  const { data, isLoading, error } = useFetchProductByFavoriteQuery({})

  const content = (): React.ReactElement => {
    let content
    if (isLoading) {
      content = <Skeleton times={3} />
    } else if (error !== undefined && error !== null) {
      console.log(error)
      content = <p> Fetching Data Error</p>
    } else {
      content = <ProductList products={data} />
    }
    return content
  }
  return (
    <>
      <Header />

      <div className="bg-white w-full my-3 px-4 lg:px-6 py-2.5">
        <SearchBar onSubmit={handleSubmitSearch} />
      </div>
      <div className="min-h-screen">
        {content()}
      </div>
    </>
  )
}

export default HomePage
