import { createApi } from '@reduxjs/toolkit/query/react'
import autoCaseConvertQuery from './autoCaseConvertQuery'

interface UserInfoType {
  name: string
  imageUrl: string
}

const userApi = createApi({
  reducerPath: 'user',
  baseQuery: autoCaseConvertQuery(),
  endpoints: (builder) => ({
    fetchUserInfo: builder.query<UserInfoType, void>({
      query: () => ({
        url: '/api/user',
        method: 'GET'
      })
    })
  })
})

export const userReducer = userApi.reducer
export const { useFetchUserInfoQuery } = userApi
export { userApi }
