import { createApi } from '@reduxjs/toolkit/query/react'
import autoCaseConvertQuery from './autoCaseConvertQuery'

interface FetchProductsParams {
  category?: string
  q?: string
  lng: number
  lat: number
  distanceTo?: number
}

const productApi = createApi({
  reducerPath: 'product',
  baseQuery: autoCaseConvertQuery(),
  endpoints: (builder) => ({
    fetchProductById: builder.query({
      query: (id: number) => ({
        url: `/api/products/${id}.json`,
        method: 'GET'
      })
    }),
    fetchProducts: builder.query({
      query: (params: FetchProductsParams) => ({
        url: '/api/products.json',
        method: 'GET',
        params
      })
    }),
    fetchProductByFavorite: builder.query({
      query: () => ({
        url: '/api/user/favorited_products.json',
        method: 'GET'
      })
    }),
    addProductToFavorite: builder.mutation({
      query: (id: number) => ({
        url: `/api/products/${id}/add_favorite.json`,
        method: 'POST'
      })
    }),
    removeProductFromFavorite: builder.mutation({
      query: (id: number) => ({
        url: `/api/products/${id}/remove_favorite.json`,
        method: 'POST'
      })
    })
  })
})

export const productReducer = productApi.reducer
export const {
  useFetchProductByIdQuery,
  useLazyFetchProductByIdQuery,
  useFetchProductsQuery,
  useFetchProductByFavoriteQuery,
  useAddProductToFavoriteMutation,
  useRemoveProductFromFavoriteMutation
} = productApi
export { productApi }
